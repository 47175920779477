(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@citizens/mfe-shared-state"), require("rxjs"));
	else if(typeof define === 'function' && define.amd)
		define(["@citizens/mfe-shared-state", "rxjs"], factory);
	else if(typeof exports === 'object')
		exports["comm-sso-account-summary-mfe"] = factory(require("@citizens/mfe-shared-state"), require("rxjs"));
	else
		root["comm-sso-account-summary-mfe"] = factory(root["@citizens/mfe-shared-state"], root["rxjs"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__1012__, __WEBPACK_EXTERNAL_MODULE__3832__) => {
return 