import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";


export interface Application {
  name: string;
  displayName:	string;
  oudAppName?: string;
  isRegistered?: boolean;
  isTradeMarked?: boolean;
  order?: number;
  homeURL?: string;
  learnMoreURL?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  appLoginJson?: AppLoginJson;
  linkText?: string;
  description?: string;
  src?: string;
}

export interface AppLoginJson {
  transmitJourney?: string;
  samlActionUrl?: string;
  relayState?: string;
  logoutUrl?: string;
  mobileLogoutUrl?: string;
}

export interface SamlJson {
  samlActionUrl?: string;
  relayState?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  // TODO: move application information into a database table
  applications: Application[]  = environment.univ_applications;
  appInfo = new Map();   // used gateway
  apps = new Map();      // used applications and linked credentials
  isCurrentRoute = false;

  // config
  configApplications: Application[] =[];
  configApps: any;
  nonOUDAppsList: any;
  oudAppsList: any;
  shortNameMap: { [displayName: string]: string } = {};
  public updateLastLoginDetailsUrl = environment.univ_updateLastLoginDetails;

  constructor(private https: HttpClient) {
    this.shortNameMap['ACHieve Access'] = 'Achieve';
    this.shortNameMap['accessOptima'] = 'AccessOptima';
    this.shortNameMap['SF'] = 'SF';
    this.shortNameMap['Citizens File Gateway'] = 'Seeburger';
    this.shortNameMap['Cash Flow Essentials'] = 'CASHFLOW';
  }

  /**
   * Currently init is getting data from the environment variables
   */
  init(){
    if (this.applications !== undefined) {
      this.applications.forEach(data => {
        this.apps.set(data.name, data);
      });

      // config
      this.getAppsData().subscribe((data:any) => {
        this.configApps = data;
        this.nonOUDAppsList = data.filter((app: any) => !app.isOUDApp).map((data: any) => data.applicationName);
        this.oudAppsList = data.filter((app: any) => app.isOUDApp).map((data: any) => data.applicationName); // config oud
        console.log(this.nonOUDAppsList, 'non OUD');
        console.log(this.oudAppsList, 'OUD');
        data.forEach((val: any) => {
          this.apps.set(val.name, val);
          this.shortNameMap[val.displayName] = val.applicationName
        });
      })
    }
  }

  getAppsData(): Observable<any[]> {
    const url = environment.univ_apps_url;
    return this.https.get<any[]>(url);
  }

  updateLastLogin(accountLoginId: any, universalId: string) {
    const url = this.updateLastLoginDetailsUrl + universalId;

    const body = {
      "accountLogin": accountLoginId,
      "lastLoginDt": ""
    };
    this.https.post<any>(url, body).subscribe(res => {
      console.log(res);
    });

  }

  getNonOUDAppsList() {
    return this.nonOUDAppsList;
  }

  getOUDAppsList() {
    return this.oudAppsList;
  }

  getConfigAppsData(selectedAppName: any) {
    return this.configApps.find((val: any) => val.name === selectedAppName);
  }

  getShortAppName(selectedAppName: any) {
    return this.shortNameMap[selectedAppName] || '';
  }

  isApp(appName: any){
    return this.apps.has(appName);
  }

  getHomeUrl(appName: any) {
    if (this.appInfo.has(appName)) {
      const app: Application = this.appInfo.get(appName);
      return app.homeURL;
    }
    return undefined;
  }

  getLearnMoreUrl(appName: any) {
    if (this.appInfo.has(appName)) {
      const app: Application = this.appInfo.get(appName);
      return app.learnMoreURL;
    }
    return undefined;
  }

  getTransmitJourney(appName: any) {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return app.appLoginJson.transmitJourney;
    }
    return undefined;
  }

  getDisplayName(appName: any) {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return app.displayName;
    }
    return undefined;
  }

  getNonOUDApps(appName: any) {
    this.getAppsData().subscribe((data: any) => {
      data.forEach((val: any) => {
        if(val.name === appName)
        return true;
      })
    })
    return false;
  }

  getOudAppName(appName: any) {
      if (this.isApp(appName)) {
        const app = this.apps.get(appName);
        return app.oudAppName;
      }
      return undefined;
    }

  getIsRegistered(appName: any) {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return app.isRegistered;
    }
    return undefined;
  }

  getIsTradeMarked(appName: any) {
    if(this.isApp(appName)){
      const app = this.apps.get(appName)
      return app.isTradeMarked;
    }
    return undefined;
  }

  getSamlInfo(appName: any) {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return { samlActionUrl: app.appLoginJson.samlActionUrl,
        relayState: app.appLoginJson.relayState };
    }
    return undefined;
  }

  getLogoutInfo(appName: any) {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return { logoutUrl: app.appLoginJson.logoutUrl,
        mobileLogoutUrl: app.appLoginJson.mobileLogoutUrl };
    }
    return undefined;
  }

  getAppInfo(appName: any) {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return {
        displayName: app.displayName,
        oudAppName: app.oudAppName,
        isRegistered: app.isRegistered,
        isActive: app.isActive
      };
    }
    return undefined;
  }

  textEllipis(value: any){
    if (value.length > 15) {
      return value.substring(0, 12) + '...';
    }
    return value;
  }
}