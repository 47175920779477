<div class="container-fluid" [ngClass]="resizedWindow ? '' : 'p-0 m-0'">
    <div class="row" *ngIf="!hideBtl">
        <div class="col-12">
            <div class="d-flex header-section pt-2 justify-content-between">
                <h5 class="card-title">My Accounts</h5>
                <div class="dropdown" *ngIf="ssoIDArr.length > 1">
                    <select name="ssoIdSelection" [ngClass]="resizedWindow ? '' : 'smallWidth' " (change)="dropdownChange($event.target.value)">
                        <option *ngFor="let item of ssoIDArr" [value]="item.ssoId">{{item.companyId}} - {{item.userId | textEllipsis}}</option>
                    </select>
                </div>
                
                <div class="form-row form-check toggle-switch toggle-btl-switch m-0 d-flex">
                    <label class="switch">
                        <input type="checkbox" id="toggleView" class="cbensightenevent" name="toggleView" value="true"
                            [(ngModel)]="toggleView" (change)="toggleViewChanged($event)" cbdata-type="button">
                        <span class="slider round"></span>
                    </label>
                    <p>Classic View</p>
                </div>
            </div>
            
            <div *ngIf="!showError">
                <app-deposit-accounts *ngIf="displayDeposits" [ssoId]="ssoId"></app-deposit-accounts>
                <app-loan-accounts *ngIf="displayLoans" [ssoId]="ssoId"></app-loan-accounts>
            </div>
            <div *ngIf="showError" class="text-center p-5">
                **No account entitled for this company ID**
            </div>
        </div>
    </div>
    <div *ngIf="hideAccountSummary" class="text-center p-5">
            {{errorMessage}}
    </div>
</div>
