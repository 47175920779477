<!-- ? only when live and tnx data is there -->
<div class="tnx-wrapper" *ngIf="transactionDetails && transactionList">
    <div class="tnx-header">
        <span class="font-weight-bold account-details">
            {{transactionDetails?.accountName}} {{transactionDetails?.availableBalance | currency: 'USD'}}
        </span>
        <div mat-dialog-actions>
            <span mat-dialog-close (click)="destroyData()" class="displayServicesBtn font-weight-bold cross-icon">
                X
            </span>
        </div>
    </div>

    <div mat-dialog-content>
        <div class="pt-0">
            <div class="table-responsive btl-table" *ngIf="transactionList.length > 0">
                <h5 class="font-weight-bold">
                    Current Day Transactions
                </h5>
                <table class="table table-hover" aria-describedby="Current Day Transactions Table">
                    <thead class="btl-thead">
                        <tr>
                            <th *ngIf="resizedWindow">Effective Date</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th *ngIf="resizedWindow && accountType === 'deposits'">Debit/Credit</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="transactionList.length > 0">
                        <tr *ngFor="let transactionData of transactionList">
                            <td *ngIf="resizedWindow">
                                <span *ngIf="transactionData.bankReference !== null">
                                    {{transactionData.effectiveDate || transactionData.postedDate}}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="transactionData.customerReference !== null">
                                    {{transactionData.description || transactionData.shortDescription }}
                                </span>
                            </td>
                            <td>
                                <span>
                                    {{transactionData.type === 'DEBIT' ? '-' : '&nbsp;'}}{{transactionData.amount |
                                    currency: 'USD'}}
                                </span>
                            </td>
                            <td class="text-wrap" *ngIf="resizedWindow && accountType === 'deposits'">
                                <span class="text-wrap">{{transactionData.type}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="transactionList.length === 0 && !showNoDataError" class="text-center font-weight-bold">
                **{{errorMsg}}**
            </div>

            <div *ngIf="transactionList.length === 0 && showNoDataError" class="text-center font-weight-bold">
                **No Current Day Transactions**
            </div>
        </div>
    </div>

    <mat-dialog-actions class="pt-0">
        <div class="table-pagination"  *ngIf=" transactionList.length > 0 && (this.enableNextBtn || this.enablePrevBtn)">
            <button [disabled]="!enablePrevBtn" class="button"
                (click)="enablePrevBtn ? handleTablePagination('previous') : '' ">
                <svg viewBox="0 0 18 18" focusable="false" [ngClass]="enablePrevBtn === true ? '' : 'disabled' "
                class="mat-mdc-paginator-icon">
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                </svg>
            </button>

            <button [disabled]="!enableNextBtn" class="button"
                (click)="enableNextBtn ? handleTablePagination('next') : '' ">
                <svg viewBox="0 0 18 18" focusable="false"
                    [ngClass]="enableNextBtn === true ? '' : 'disabled' "
                    class="mat-mdc-paginator-icon">
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                </svg>
            </button>
        </div>
        <div class="buttonArea p-2 mt-2">
            <button #btnLogin (click)="openViewMore()" aria-label="View More" mat-dialog-close
                class="back cbensightenevent" id="btnLogin" name="btnLogin" cbdata-type="button"
                cbdata-reason="View More Transactions" tabindex="6" type="submit" value="View More">
                Take me to {{btnLabel}}
            </button>
        </div>
    </mat-dialog-actions>
</div>

<ng-template #modal let-cross="close" let-dismiss="dismiss">
    <app-message-dialog [title]="" [cross]="cross" [dismiss]="dismiss" class="justify-content-center">
      <div class="boldDialogText">You have one active session in
        <span [ngClass]="{ 'registered': modalIsRegistered }">{{ modalDisplayName }}</span>.
      </div>
      <div>Please, log out from <span [ngClass]="{ 'registered': modalIsRegistered }">{{ modalDisplayName }}</span>
        and close the tab before accessing with a different credential.</div>
    </app-message-dialog>
</ng-template>

<!-- new application tab container -->
<ng-container #container></ng-container>

<!-- <app-alert></app-alert> -->