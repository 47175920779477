import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SsoUtils } from './sso-utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UniversalSessionService {

  public codeVerifier: any;
  private universalId: string = '';
  private tmxUnivSessionId: any;    // threatmetrix tmxUnivSessionId that can change
  private analyticsSessionId = '';   // a constant sessionId for Analytics
  private eventType = '';
  private appName = 'universal';
  private pageName = '';
  private isRemembered = false;
  private troubleType = '';
  private analyticsUserId = '';
  private cookieData: any;
  private errorDescription = '';
  private cookiePrefix = 'mvp';
  private foundCookie = false;
  private keepAnalyticsSessionId = false;
  private legacyInfo = {};
  private universalInfo = {};
  private mobile = false;      // mobile responsive
  private mobileApp = false;   // Mobile App & Device is determined from the route 'mobile-login'
  private isUniversalLoginFlow = false;
  private addCreds: any;
  private universalProfileContactInfo: any;
  private universalProfileContactInfoPrev: any;
  private previousPageName = '';
  private isAuthenticated = 'n';
  private webSessionId = '';
  private ssoTokenStatus: string = '';
  private entryPageInitialization = false;    // used by the redirect guard
  private registrationErrorPayload: any;
  private cancelSelection: boolean = false;
  private updateProfileOTPLockRedirect: boolean = false;
  public enrollApiBody: any;
  public enrollNonOudApiBody: any;
  private flowComplete: boolean = false;

  public cancelUrlMVP: string = environment.univ_defaultLogoutUrl;
  public cancelMobileUrlMVP: string = environment.univ_defaultMobileLogoutUrl;
  private butlerApp = false;
  public userIdMaps: any = {};
  revokePFCookieUrl: string = '';



  constructor(private cookieService: CookieService,
    private ssoUtils: SsoUtils,
    private https: HttpClient) {
  }


  setSSOTokenValidation(message: string) {
    this.ssoTokenStatus = message;
  }

  getSSOTokenStatus(): string {
    return this.ssoTokenStatus;
  }

  getUserIdMaps() {
    return this.userIdMaps;
  }

  setUserIdMaps(data: any) {
    this.userIdMaps = data;
  }

  static getRegion(): string {
    let region = 'dev';
    if (environment.production) {
      region = 'prod';
    } else if (environment.isQA) {
      region = 'QA';
    } else if (environment.isQA2) {
      region = 'QA2';
    } else if (environment.isSIT) {
      region = 'SIT';
    }
    return region;
  }

  static generateTimeString(scrambled = false): string {
    const dateTime = new Date();
    const mon = dateTime.getMonth();
    const dt = dateTime.getDate();
    const hrs = dateTime.getHours();
    const secs = dateTime.getSeconds();
    const ms = dateTime.getMilliseconds();
    const min = dateTime.getMinutes();
    if (scrambled) {
      return `${ms}-${hrs}-${dt}-${mon}-${secs}-${min}`;
    }
    return `${mon}-${dt}-${hrs}-${min}-${secs}-${ms}`;
  }

  initializeSession(pageName: string, keepAnalyticsSessionId = true) {
    this.eventType = 'login';
    this.pageName = pageName;
    this.getCookie();
    this.analyticsSessionId = keepAnalyticsSessionId ? this.getAnalyticsSessionId(true) : this.generateAnalyticsSessionId();
    this.analyticsUserId = keepAnalyticsSessionId ? this.getAnalyticsUserId(true) : this.getAnalyticsUserId();
    this.keepAnalyticsSessionId = true;

    // initialization should ONLY be done on entry pages !!
    if (pageName === 'login' || pageName === 'enroll' || pageName === 'dashboard') {
      this.entryPageInitialization = true;
    }

    if (pageName === 'login') {
      this.getCookie();
      if (this.cookieData) {
        this.isAuthenticated = this.cookieData.isAuthenticated;
      }
    }

    this.setCookie({
      tmxUnivSessionId: this.tmxUnivSessionId,
      analyticsSessionId: this.analyticsSessionId,
      analyticsUserId: this.analyticsUserId,
      isAuthenticated: this.isAuthenticated,
      webSessionId: this.webSessionId
    });
    this.getRememberedCookies();

    const data = {
      tmxUnivSessionId: this.tmxUnivSessionId,
      analyticsUserId: this.analyticsUserId,
      analyticsSessionId: this.analyticsSessionId,
      universalId: this.universalId,
      pageName: this.pageName,
      isRemembered: this.isRemembered,
      previousPageName: this.previousPageName,
      isAuthenticated: this.isAuthenticated,
      webSessionId: this.webSessionId,
      mobileApp: this.mobileApp,
      butlerApp: this.butlerApp
    };

    return data;
  }

  entryFlowStarted(): boolean {
    return this.entryPageInitialization;
  }

  getCookieName(): string {
    const region = UniversalSessionService.getRegion();
    return `__Host-${this.cookiePrefix}_${this.appName}_${region}`;
  }

  getCookie() {
    let cookie = '';
    const cookieName = this.getCookieName();

    try {
      if (cookieName !== undefined || cookieName !== '') {
        const encryptedCookie = this.cookieService.get(cookieName);
        cookie = this.ssoUtils.decryptUsingAES(encryptedCookie);
      }
      this.cookieData = (cookie !== '') ? JSON.parse(cookie) : undefined;
    } catch (err) {
      // continue as if there is no old cookie and create a new one
      console.error('cookie parse error. ', err);
    }
    if (this.cookieData !== undefined) {
      this.foundCookie = true;
    }
  }

  setCookie(json: any) {
    this.getCookie();
    const cookieName = this.getCookieName();
    let data = this.cookieData;

    if (this.cookieData === undefined) {
      data = {
        tmxUnivSessionId: this.tmxUnivSessionId,
        analyticsUserId: this.analyticsUserId,
        analyticsSessionId: this.analyticsSessionId,
        universalId: this.universalId,
        pageName: this.pageName,
        isRemembered: this.isRemembered,
        previousPageName: this.previousPageName,
        errorDescription: this.errorDescription,
        troubleType: this.troubleType,
        isAuthenticated: this.isAuthenticated,
        webSessionId: this.webSessionId,
        mobileApp: this.mobileApp,
        butlerApp: this.butlerApp
      };

      this.foundCookie = true;
    }

    const finalCookie = Object.assign({}, data, json);
    this.cookieData = finalCookie;
    const encryptedCookie = this.ssoUtils.encryptUsingAES(JSON.stringify(finalCookie));
    this.cookieService.set(cookieName, encryptedCookie, 180, '/', undefined, true, 'Lax');
  }

  setRememberedCookies(aUniversalId: string) {
    this.universalId = aUniversalId;
    this.isRemembered = true;
    this.setCookie({ universalId: this.universalId, isRemembered: this.isRemembered });
  }

  deleteRememberedCookies() {
    this.isRemembered = false;
    this.setCookie({ universalId: this.universalId, isRemembered: this.isRemembered });
  }

  setSessionInfo(webSessionId: any, userProfileId: any) {
    this.analyticsUserId = userProfileId;  // unique identifier used for Analytics
    this.webSessionId = webSessionId;
    this.setCookie({
      analyticsUserId: this.analyticsUserId,
      webSessionId: this.webSessionId
    });
  }

  setAddCredsRes(addCreds: any) {
    this.addCreds = addCreds;
  }

  setUserInfo(universalId: string, remember: boolean) {
    console.warn('>> setUserInfo remember=', remember);
    if (remember) {
      this.setRememberedCookies(universalId);
    } else {
      this.setUniversal(universalId);
    }
  }

  setTroubleType(troubleType: string) {
    this.troubleType = troubleType;
    this.setCookie({ troubleType: this.troubleType });
  }

  setLegacyInfo(info: any) {
    this.legacyInfo = info;
  }

  setIsUniversalLoginFlow(val: boolean) {
    this.isUniversalLoginFlow = val;
  }

  setUniversalProfileContactInfo(val: any) {
    this.universalProfileContactInfo = val;
  }

  getUniversalProfileContactInfo(): any {
    return this.universalProfileContactInfo;
  }

  getLegacyInfo() {
    return this.legacyInfo;
  }

  setUniversalInfo(universalInfo: any) {
    this.universalInfo = universalInfo;
  }

  getUniversalInfo() {
    return this.universalInfo;
  }

  setUniversal(aUniversalId: string) {
    this.universalId = aUniversalId;
    this.setCookie({
      universalId: this.universalId
    });
  }

  getUniversal() {
    console.warn('getUniversal >> this.universalId=', this.universalId);
    return this.universalId;
  }

  hasSession(): boolean {
    return (this.tmxUnivSessionId !== undefined);
  }

  getAddCredsRes() {
    return this.addCreds;
  }

  hasTmxUnivSession(): boolean {
    return this.tmxUnivSessionId !== undefined;
  }

  getSessionData(pageName: string, keepAnalyticsSessionId: boolean = true): any {
    this.getCookie();
    if (this.hasSession()) {
      return {
        tmxUnivSessionId: this.cookieData !== undefined ? this.cookieData.tmxUnivSessionId : this.tmxUnivSessionId,
        universalId: this.cookieData !== undefined ? this.cookieData.universalId : this.universalId,
        analyticsUserId: this.cookieData !== undefined ? this.cookieData.analyticsUserId : this.analyticsUserId,
        analyticsSessionId: this.cookieData !== undefined ? this.cookieData.analyticsSessionId : this.analyticsSessionId,
        pageName: this.pageName,
        previousPageName: this.previousPageName,
        isRemembered: this.cookieData !== undefined ? this.cookieData.isRemembered : this.isRemembered,
        isAuthenticated: this.cookieData !== undefined ? this.cookieData.isAuthenticated : this.isAuthenticated,
        mobileApp: this.mobileApp,
        butlerApp: this.butlerApp
      };
    } else {
      return this.initializeSession(pageName, keepAnalyticsSessionId);
    }
  }

  getRememberedCookies() {
    this.getCookie();
    if (this.cookieData !== undefined) {
      this.universalId = this.cookieData.universalId;
      this.isRemembered = this.cookieData.isRemembered;
      this.mobileApp = this.cookieData.mobileApp;
      this.previousPageName = this.cookieData.previousPageName;
      this.butlerApp = this.cookieData.butlerApp;
    }
  }

  getAppName() {
    return this.appName;
  }

  /**
   *  get a threatmetrix tmxUnivSessionId
   */
  getSessionID() {
    return this.tmxUnivSessionId;
  }


  getIsUniversalLoginFlow(): boolean {
    return this.isUniversalLoginFlow;
  }

  /**
   * Used to end a threatmetrix tmxUnivSessionId
   * which will trigger a generation of  new threatmetrix tmxUnivSessionId
   * on the new page/screen
   */
  endTmxUnivSessionID() {
    this.tmxUnivSessionId = undefined;
  }

  // Terminating WebSessionId from backend..

  // tab keep alive!
  extendWebSession(): Observable<any> {
    const url = environment.univ_extendSession;
    const params = new HttpParams().set('webSessionId', this.webSessionId);

    return this.https.get(url, { params })
      .pipe(retry(1));
  }

  /**
   * Ending All TMX Sevice by ending all sessions except for the
   * transmit session and Analytics session and this will trigger on click of cancel button anywhere in application.
   */
  cancelSessions() {
    // set a cookie just for analytics
    this.cookieService.set('__Host-mvp_ana', 'true', 1, '/', undefined, true, 'Lax');
    this.endTmxUnivSessionID();
  }

  /**
   * generate an Analytics sessionId that NEVER changes during a journey
   */
  generateAnalyticsSessionId() {
    const sessionIdRandom = Math.floor(Math.random() * 100000000);
    const end = UniversalSessionService.generateTimeString(true);
    this.analyticsSessionId = `ana-${sessionIdRandom}-${end}`;
    return this.analyticsSessionId;
  }

  getTroubleType() {
    if ((this.troubleType === '' || this.troubleType === undefined) && this.cookieData !== undefined) {
      this.troubleType = (!!this.cookieData) ? this.cookieData.troubleType : '';
    }
    return this.troubleType;
  }

  /**
   * TODO:  set the code when in MVP we move away from cookies for now just remove from the json
   *  { description: 'text error description', code: {code if there is one} }
   * @param json error description text
   */
  setErrorDescription(json: any) {
    json.code = '';    // remove from cookie for alpha/beta
    this.errorDescription = json;
    this.setCookie({ errorDescription: this.errorDescription });
  }

  getErrorDescription(): any {
    if ((this.errorDescription === '' || this.errorDescription === undefined) && this.cookieData !== undefined) {
      this.errorDescription = (!!this.cookieData) ? this.cookieData.errorDescription : '';
    }
    return this.errorDescription;
  }

  getAnalyticsSessionId(fromCookie = false): any {
    if (fromCookie) {
      this.getCookie();
      this.analyticsSessionId = (this.cookieData !== undefined) ? this.cookieData.analyticsSessionId : '';
    }
    return this.analyticsSessionId;
  }

  getAnalyticsUserId(fromCookie = false): any {
    if (fromCookie) {
      this.getCookie();
      this.analyticsUserId = (this.cookieData !== undefined) ? this.cookieData.analyticsUserId : '';
    }
    return this.analyticsUserId;
  }

  // get the keepAnalytics cookie
  getKeepAnalyticsSession(): any {
    const cookieKeep = this.cookieService.get('__Host-mvp_ana');
    this.keepAnalyticsSessionId = (cookieKeep !== undefined) ? cookieKeep === 'true' : false;
    return this.keepAnalyticsSessionId;
  }

  keepAnalyticsSession(pageName: any) {
    if (pageName !== 'login' && pageName !== 'enroll') {
      return true;
    }
    return this.getKeepAnalyticsSession();
  }

  setMobile(val: boolean) {
    this.mobile = val;
  }

  // actually Mobile Responsive
  isMobile() {
    return this.mobile;
  }

  // Mobile App & Device should only be set from the universal-login component
  setMobileApp(val: boolean) {
    this.mobileApp = val;
    this.setCookie({
      mobileApp: this.mobileApp
    });
  }

  // Mobile App & Device
  isMobileApp() {
    return this.mobileApp;
  }

  isPrmMobileApp(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        resolve(this.mobileApp);
    });
  }

  //Butler app
  setButlerApp(val: boolean) {
    this.butlerApp = val;
    this.setCookie({ butlerApp: val });
  }

  isButlerApp() {
    return this.butlerApp;
  }

  setPreviousPageName(pageName: string) {
    this.previousPageName = pageName;
    this.setCookie({
      previousPageName: this.previousPageName
    });
  }

  getPreviousPageName() {
    return this.previousPageName;
  }


  /**
   * used for analytics only
   * @param auth boolean to show authentication status
   */
  setAuthenticated(auth: boolean) {
    this.isAuthenticated = auth ? 'y' : 'n';
    this.setCookie({
      isAuthenticated: this.isAuthenticated
    });
  }

  getAuthenticated() {
    return this.isAuthenticated;
  }

  isSessionAuthenticated(): boolean {
    this.getCookie();
    let data = this.cookieData;
    if (data !== undefined) {
      this.isAuthenticated = data.isAuthenticated;
    }
    return this.isAuthenticated === 'y';
  }

  setWebSession(webId: string) {
    this.webSessionId = webId;
    this.setCookie({
      webSessionId: this.webSessionId
    });
  }

  getWebSession() {
    return this.webSessionId;
  }

  setRegistrationErrorPayload(registrationErrorPayload: object) {
    this.registrationErrorPayload = registrationErrorPayload;
  }

  getRegistrationErrorPayload() {
    return this.registrationErrorPayload;
  }

  getCancelSelection() {
    return this.cancelSelection;
  }

  setCancelSelection(cancelSelection: any) {
    this.cancelSelection = cancelSelection;
  }

  setUpdateProfileOTPLockRedirect(updateProfileOTPLockRedirect: any) {
    this.updateProfileOTPLockRedirect = updateProfileOTPLockRedirect;
  }
  getUpdateProfileOTPLockRedirect() {
    return this.updateProfileOTPLockRedirect;
  }

  setFlowComplete(value: any) {
    this.flowComplete = value;
  }

  getFlowComplete() {
    return this.flowComplete;
  }


  private handleError(error: HttpErrorResponse): Observable<never> {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      msg = 'Client-side error: ${error.error.message}';
    } else {
      msg = 'Server-side error: ${error.error.message}';
    }

    console.error(msg);
    return throwError('Something went wrong: please try again later.');
  }
}