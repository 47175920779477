import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { assetURL } from 'src/environments/environment';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

  @Input() title: any;
  @Input() dismiss: any;
  @Input() cross: any;

  get assetURL() {
    return assetURL;
  }

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
