import { Component, ComponentFactoryResolver, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ApplicationService } from 'src/app/core/service/application.service';
import { ModalService } from 'src/app/core/service/modal.service';
import { SpinnerService } from 'src/app/core/service/spinner.service';
import { SsoService } from 'src/app/core/service/app.service';
import jwt_decode from 'jwt-decode';
import { AlertService } from 'src/app/core/service/alert.service';
import { TabService } from 'src/app/core/service/tab.service';
import { HeaderTransmitService } from 'src/app/core/service/transmit.service';
import { TransmitUtils } from 'src/app/core/service/transmit-utils';
// import { WindowComponent } from '../window/window.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { assetURL, environment } from 'src/environments/environment';

@Component({
  selector: 'app-loan-accounts',
  templateUrl: './loan-accounts.component.html',
  styleUrls: ['./loan-accounts.component.css']
})
export class LoanAccountsComponent {
  @Input() ssoId: 'string'
  public loanAccountsData: any = [];
  public viewAll: boolean = false;
  public showTileView: boolean = true;
  public totalAvailableBalance: string | number = '';
  public displaySpinner: boolean = false;
  public errorMessage: string;
  public isError: boolean = false;

  // table pages
  totalTablePages: number = 1;

  firstThreeAcc: any = [];
  restAcc: any = [];

  // Material Pagination
  length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  public resizedWindow: boolean = false;
  public spinnerTitle: string = 'Loading Loan Accounts...';

  @ViewChild('modal') tabModal!: TemplateRef<any>;
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  public samlUrl: string = '';
  public samlInfo: string = '';
  public tabName: string = '';
  public universalId: string = '';
  public modalDisplayName: string = '';
  public modalIsRegistered: boolean = false;
  private legacyEmail: string = '';
  private personaDetailsExtesnionJson: {} | undefined;

  constructor(
    private appService: ApplicationService, 
    protected modalService: ModalService,
    private spinnerService: SpinnerService,
    private appService1: SsoService,
    private alertService: AlertService,
    private resolver: ComponentFactoryResolver,
    private tabService: TabService,
    private transmitService: HeaderTransmitService,
    private transmitUtils: TransmitUtils,
    private ngbModalService: NgbModal
  ) {
    this.resizedWindow = this.modalService.setWindow();
  }

  ngOnInit(): void {
    this.getLoanData({ pageIndex: 0, pageSize: 12 });
    this.universalId = sessionStorage.getItem('universalId') ?? '';
  }

  ngAfterViewInit(): void {
    this.transmitService.initializeTransmit(environment.transmitUrl);
  }

  get isTableVisible() {
    if (this.appService.getViewToggleValue()) {
      this.showTileView = false
    }
    return this.appService.getViewToggleValue()
  }

  public getLoanData: any = (pageInfo: any) => {
    this.displaySpinner = true;
    this.appService.getFunction(
      `v1/accounts/LOANACCTS?pageOffset=${((pageInfo['pageIndex']) * pageInfo['pageSize']) + 1}&pageLimit=${pageInfo['pageSize']}`,
      { 'SSO_ID': `${this.ssoId}` }
    ).subscribe((response) => {
      this.displaySpinner = false;
      this.handleResponse(response)
    }, (error) => {
      this.isError = true;
      this.displaySpinner = false;
      this.errorMessage = `**Unable to display loan accounts, please check the balances in ${this.appService.appName}**`;
      console.error('Error fetching loan data:', error);
    })
  }

  public handleResponse(response: any) {
    if(response.status === 200) {      
      this.loanAccountsData = response.data;
      this.totalTablePages = this.loanAccountsData.totalPages;
      this.totalAvailableBalance = this.calculateTotalAvailableBalance(this.loanAccountsData.accountSummaries)
      this.splitAccountSummaryData(this.loanAccountsData.accountSummaries)
      this.checkTileViewValue()
    } 
  }

  public calculateTotalAvailableBalance(accountSummaries: any[]): number {
    return accountSummaries.reduce((b: any, a: any) => b + a.availableBalance, 0);
  }

  public splitAccountSummaryData(accountSummaries: any[]){
    this.firstThreeAcc = accountSummaries.slice(0, 3);
    this.restAcc = accountSummaries.slice(3);
  }

  public getTransactionList: any = (accountInfo: any) => {
    this.spinnerService.setText('Please wait...');
    this.spinnerService.showSpinner();
    const accountDetails = {
      clientName: accountInfo.clientAccountName,
      accountName: accountInfo.accountName,
      accountNumber: accountInfo.accountNumber,
      accountFilter: accountInfo.accountFilter,
      availableBalance: accountInfo.availableBalance,
      principalBalance: accountInfo.principalBalance,
      ssoId: this.ssoId
    }

    if (!accountInfo.isRealTimeAccount) {
      this.openViewMore(accountDetails);
      return;
    }

    this.modalService.setAccountValues(accountDetails, 'loans')
    this.modalService.setWindow()
    this.modalService.openDialog().then(() => {
      // will be hiding in transaction comp
    }).catch(() => {
      this.spinnerService.hideSpinner();
    })
  }

  public checkViewAll: any = () => {
    this.viewAll = !this.viewAll
    this.checkTileViewValue()
  }

  public checkTileViewValue: any = () => {
    this.showTileView = this.loanAccountsData.totalRows > 12 ? false : true
  }

  handleTablePagination(actionType: any) {
    this.getLoanData(actionType);
  }

  openViewMore(accountDetail: any) {
    const getSsoIdArr = this.appService.getSsoIdArrValue()
    const selectSsoId = getSsoIdArr.find((ssoIdData: any) => ssoIdData['ssoId'] === accountDetail['ssoId'])

    // const openAOEvent = new CustomEvent("open-access-optima", {
    //   detail: { from: "btl-application", ssoIdData: selectSsoId }
    // });
    // dispatchEvent(openAOEvent);
    // this.login(selectSsoId)
  }

  // login(item:any) {
  //   let userId = item.userId;
  //   let compId = item.companyId;
  //   let appName = item.applicationName === 'cashflow' ? item.applicationName.toUpperCase() : item.applicationName;
  //   let displayName = item.displayName;
  //   let isRegistered = item.isRegistered;
  //   let accountLogin = item.ssoId;
  //   let personalDetailExtension = item.personaDetailsExtension;
  //   let webAppId = this.appService1.getTransmitJourney(item.applicationName);
  //   console.log("chckng if AO btn event is triggere",item)
  //   if (personalDetailExtension === undefined) {
  //     this.legacyEmail = '';
  //   } else {
  //     this.legacyEmail = personalDetailExtension.legacyEmailAddress;
  //     this.personaDetailsExtesnionJson = personalDetailExtension.personaDetailsExtesnionJson;
  //     if ((typeof this.personaDetailsExtesnionJson) === 'string') {
  //       this.personaDetailsExtesnionJson = JSON.parse(personalDetailExtension.personaDetailsExtesnionJson);
  //     }
  //   }
  //   this.alertService.hide();
  //   this.spinnerService.clearText();
  //   // TODO actual login!
  //   this.appService1.updateLastLogin(accountLogin, this.universalId);
  //   // Last Login Details Update to DB

  //   const samlInfo: any = this.appService1.getSamlInfo(appName);
  //   this.samlUrl = samlInfo.samlActionUrl;
  //   this.tabName = appName;
  //   this.samlInfo = 'data';     // TODO: change it!
  //   const isOpen = this.tabService.isAppTabOpen(appName);

  //   if (isOpen) {
  //     // open dialog message to close tab
  //     this.openMessageDialog(displayName, isRegistered);
  //   } else {

  //     // open a window component
  //     const factory = this.resolver.resolveComponentFactory(WindowComponent);
  //     const preprodValue = sessionStorage.getItem('preprod');
  //     let journeyName = 'ping_dropoff';

  //     // seperate route for non SAML flow
  //     console.log(this.appService1.getConfigAppsData(appName)?.usesHeaderInjection)
  //     console.log(this.appService1.getConfigAppsData(appName)?.baseUrls)
  //     if (this.appService1.getConfigAppsData(appName)?.usesHeaderInjection) {
  //       const url = new URL(this.appService1.getConfigAppsData(appName)?.baseUrls?.login)
  //       console.log(url)
  //       const hintValue = userId + "_" + (compId ? compId : '')
  //       console.log(hintValue);
  //       let params = new URLSearchParams;
  //       params.append('hint', hintValue);
  //       const redirectUrl = `${url}?${params.toString()}`;
  //       var newTab = window.open(this.appService1.getConfigAppsData(appName)?.baseUrls?.logout)
  //       setTimeout(function () {
  //         if (newTab && !newTab.closed) {
  //           newTab.close();
  //           window.open(redirectUrl)
  //         }
  //       }, 2000);

  //     } else {
  //       // route for SAML flow
  //       this.transmitService.pingDropOffTransmitInvokePolicy(userId, compId, appName, webAppId, accountLogin, journeyName, this.universalId, this.legacyEmail, preprodValue, this.personaDetailsExtesnionJson).then((res: any) => {
  //         console.log('token', res._token);
  //         const tokenInfo = this.getDecodedAccessToken(res._token);

  //         if (tokenInfo === undefined || tokenInfo === null || tokenInfo === '') {
  //           this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
  //           return;
  //         }

  //         let ping_ref_id = tokenInfo['ping_ref_id'];
  //         let ping_login_url = tokenInfo['ping_sso_url'];


  //         if (ping_login_url === undefined || ping_login_url === null || ping_login_url === '') {
  //           this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
  //           return;
  //         }

  //         const appTabComponent = this.container.createComponent(factory).instance;
  //         appTabComponent['appName'] = this.tabName;
  //         appTabComponent['SamlUrl'] = ping_login_url;


  //         this.tabService.saveAppComponentTab(appName, appTabComponent);

  //       }).catch((error: any) => {

  //         console.log('error', error);
  //         if (this.getNested(error, '_data', 'failure_data', 'reason', 'data', 'error', 'numcode') == '3211') {
  //           item.disabled = true;
  //           this.alertService.error(this.transmitUtils.getErrorMessage('3211'));
  //           setTimeout(() => {window.scrollTo(0, 0)},0);
  //         } else if (this.getNested(error, '_data', 'failure_data', 'reason', 'data', 'error', 'numcode') == '3220') {
  //           item.locked = true;
  //           this.alertService.error(this.transmitUtils.getErrorMessage('3220'));
  //           setTimeout(() => {window.scrollTo(0, 0)},0);
  //         } else {
  //           this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
  //           setTimeout(() => {window.scrollTo(0, 0)},0);
  //         }
  //         // this.service.previousDisableState.next(this.data);
  //       });
  //     }
  //   }
  // }

  // getDecodedAccessToken(token: string): any {
  //   try {
  //     return jwt_decode(token);
  //   } catch (Error) {
  //     return null;
  //   }
  // }

  // getNested(obj:any, ...args:any) {
  //   return args.reduce((obj:any, level:any) => obj && obj[level], obj);
  // }

  // openMessageDialog(displayName:any, isRegistered:any) {
  //   this.modalDisplayName = displayName;
  //   this.modalIsRegistered = isRegistered;
  //   this.ngbModalService.open(this.tabModal, { size: 'sm', centered: true });
  // }

}
